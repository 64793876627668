<template>
  <div>
    <v-card
      class="mb-3"
      elevation="3"
      @click="handleClick"
    >
      <v-card-title class="font-weight-regular">
        <v-row class="mb-0 pb-0">
          <v-col>
            {{ myTask.name }}
          </v-col>
          <v-col
            class="pt-0"
          >
            by
            <user-button
              :user-i-d="myTask.creator._id"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        {{ myTask.text }}
        <time-thingy
          :time="myTask.created"
          text-before="created "
          text-after=" ago."
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="loadingDone"
          outlined
          color="primary"
          :to="`/completions/${myTask._id}`"
        >
          {{ solutionsString }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import apiClient from '../../apiClient';
import UserButton from './UserButton.vue';
import TimeThingy from '../technical/TimeThingy.vue';

export default {
  name: 'TaskTeaser',
  components: {
    UserButton,
    TimeThingy,
  },
  props: {
    myTask: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      totalCount: 0,
      loadingDone: false,
    };
  },
  computed: {
    solutionsString() {
      if (!this.loadingDone) {
        return '';
      }
      if (this.totalCount === 1) {
        return 'completed once';
      }
      if (this.totalCount === 0) {
        return 'not completed yet';
      }
      return `completed ${this.totalCount} times`;
    },
  },
  mounted() {
    // Get solutions
    let requestObject = {};
    requestObject = {
      limit: 1,
      listType: 'taskSolutionCount',
      task: this.myTask._id,
    };
    apiClient.post('adminGetSolutions', requestObject)
      .then(response => {
        this.totalCount = response.data.totalCount;
        this.loadingDone = true;
      })
      .catch(() => {
      });
  },
  methods: {
    handleClick() {
      this.$router.push(`/task/${this.myTask._id}`);
    },
  },
};
</script>

<style>

</style>
