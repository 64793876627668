<template>
  <div>
    <div v-if="taskList && taskList.length > 0 || search.length > 0">
      <v-card
        class="mb-3"
        elevation="3"
      >
        <v-card-title>
          <v-row>
            <v-col>
              <v-select
                v-model="sortModel"
                dense
                :items="sortArray"
                menu-props="auto"
                label="Select"
                hide-details
                single-line
                :append-icon="sortAsc ? 'mdi-sort-ascending' : 'mdi-sort-descending'"
                @click:append="switchAsc()"
                @change="switchOrder()"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="search"
                dense
                append-icon="mdi-magnify"
                label="Search"
                clearable
                single-line
                hide-details
                @input="performSearch"
              />
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>

      <v-divider />
      <spinner v-if="!isLoaded" />

      <div v-if="isLoaded">
        <TaskTeaser
          v-for="currentTask in taskList"
          :key="currentTask._id"
          :my-task="currentTask"
        />
        <v-row>
          <v-col>
            <v-pagination
              v-if="totalPages > 1"
              v-model="page"
              :length="totalPages"
              @input="switchPages"
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '../../apiClient';
import TaskTeaser from '../divViews/TaskTeaser.vue';
import Spinner from '../technical/Spinner.vue';


export default {
  name: 'TaskList',
  components: {
    TaskTeaser,
    Spinner,
  },
  props: {
    listLength: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  data() {
    return {
      isLoaded: false,
      taskList: [],
      totalCount: 0,
      search: '',
      page: 1,
      skip: 0,
      sortArray: [],
      sortModel: 'created',
      sortAsc: false,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil((this.totalCount) / this.listLength);
    },
  },
  mounted() {
    // Setup

    this.sortArray = [
      {
        text: 'Sort by time of creation',
        value: 'created',
      },
      {
        text: 'Task name',
        value: 'name',
      },
    ];

    // eslint-disable-next-line prefer-destructuring
    this.sortModel = this.sortArray[0].value;

    // Get Tasks
    this.updateData();
  },
  methods: {
    switchPages() {
      this.skip = (this.listLength * (this.page - 1));
      this.updateData();
    },
    switchAsc() {
      this.resetPagination();
      this.sortAsc = !this.sortAsc;
      this.updateData();
    },
    switchOrder() {
      this.resetPagination();
      this.updateData();
    },
    performSearch() {
      this.resetPagination();
      this.updateData();
    },
    resetPagination() {
      this.page = 1;
      this.skip = 0;
    },
    updateData() {
      this.isLoaded = false;
      let requestObject = {};
      requestObject = {
        listType: this.listType,
        user: this.listIdentifier,
        query: this.search,
        limit: this.listLength,
        skip: this.skip,
        sortBy: this.sortModel,
        sortAsc: this.sortAsc,
      };
      apiClient.post('adminTasks', requestObject)
        .then(response => {
          this.isLoaded = true;
          this.taskList = response.data.tasks;
          this.totalCount = response.data.totalCount;
        })
        .catch(() => {
        });
    },
  },
};
</script>
