import Identicon from 'identicon.js';
import _ from 'lodash';
import apiClient from './apiClient';
import store from './store';

const tools = {
  getParagraphs: inputString => {
    const splitValue = inputString.split(/\n/g);
    const returnArray = [];
    splitValue.forEach(currentLine => {
      let isText = true;
      let currentObject;
      if (currentLine.length > 8 && currentLine.substring(0, 6) === '[img="' && currentLine.substr(currentLine.length - 2) === '"]') {
        // IMG
        const url = currentLine.replace('[img="', '').replace('"]', '');
        const regEx = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm;
        if (url.match(regEx)) {
          isText = false;
          currentObject = {
            type: 'img',
            url,
          };
        }
      } else if (currentLine.length > 14 && currentLine.substring(0, 7) === '[link="' && currentLine.substr(currentLine.length - 7) === '[/link]') {
        // URL
        const url = tools.getStringBetween(currentLine, '[link="', '"]');
        const text = tools.getStringBetween(currentLine, '"]', '[/link]');
        const regEx = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm;
        if (url.match(regEx) && text.length > 0) {
          isText = false;
          currentObject = {
            type: 'link',
            url,
            text,
          };
        }
      }

      if (isText) {
        currentObject = {
          type: 'text',
          text: currentLine,
        };
      }
      returnArray.push(currentObject);
    });

    return returnArray;
  },

  getRestrictedParagraphs: inputString => {
    const returnArray = [];

    // Splitting the input string into lines, then flattening the resulting array
    // after splitting each line into segments around inline image tags
    const splitValue = inputString.split(/\n/g).flatMap(line => line.split(/(\[img="[^"]*"\])/g));

    splitValue.forEach(currentSegment => {
      let currentObject;
      if (currentSegment.startsWith('[img="') && currentSegment.endsWith('"]')) {
        // IMG
        const url = currentSegment.replace('[img="', '').replace('"]', '');
        const regEx = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/gm;
        if (url.match(regEx)) {
          currentObject = {
            type: 'img',
            url,
          };
        }
      } else {
        // Text
        currentObject = {
          type: 'text',
          text: currentSegment,
        };
      }

      returnArray.push(currentObject);
    });

    return returnArray;
  },

  getImageStringForUser: (user, fallback = true, forceFullRes = false) => {
    if (!forceFullRes && user && user.photo && user.photo.length > 0) {
      return `data:image/jpeg;base64,${user.photo}`;
    }

    if (fallback) {
      if (user && user._id && user._id.length >= 15) {
        const options = {
          foreground: [164, 164, 164, 255], // rgba black
          background: [29, 29, 29, 255], // rgba white
          margin: 0.2, // 20% margin
          size: 512, // 420px square
          format: 'svg', // use SVG instead of PNG
        };
        const buffer = new Identicon(user._id, options).toString();
        return `data:image/svg+xml;base64,${buffer}`;
      }
      return '';
    }
    return '';
  },
  insertImageTagInElement: (text = '', element) => {
    const tagToInsert = '\n[img="https://INSERT_LINK_TO_IMAGE"]\n';
    const cursorIndex = element.selectionEnd;
    return text.substring(0, cursorIndex) + tagToInsert + text.substring(cursorIndex);
  },
  insertLinkTagInElement: (text = '', element) => {
    const tagToInsert = '\n[link="https://INSERT_LINK"]INSERT_TEXT[/link]\n';
    const cursorIndex = element.selectionEnd;
    return text.substring(0, cursorIndex) + tagToInsert + text.substring(cursorIndex);
  },
  getStringBetween: (str, start, end) => str.substring(str.lastIndexOf(start) + start.length, str.lastIndexOf(end)),
  sendLogMessage: logMessage => {
    const reqObject = {
      logMessage,
    };
    apiClient.post('/frontendLog', reqObject);
  },
  updateUser: userID => {
    const cacheTimeoutMinutes = 5;

    // Check for cached item.
    const cachedUser = store.getters.getCachedUser(userID);

    // Create if not there
    if (!cachedUser) {
      const emptyUser = {
        _id: userID,
        photo: '',
        pending: true,
      };
      store.commit('setCachedUser', emptyUser);
    }
    let updateUser = true;
    // Check Timestamp if there
    if (cachedUser && cachedUser.timestamp > 0) {
      if (cachedUser.timestamp.getTime() > new Date().getTime() - cacheTimeoutMinutes * 60 * 1000 || cachedUser.pending) {
        updateUser = false;
      }
    }

    // Update if not there or too old
    if (updateUser) {
      const requestObject = {
        userID,
        simple: true,
      };
      apiClient
        .post('/userdetail', requestObject)
        .then(response => {
          const newUser = response.data;
          _.set(newUser, 'timestamp', new Date());
          store.commit('setCachedUser', newUser);
        })
        .catch(() => {
          // err
        });
    }
  },
};

export default tools;
