<template>
  <div>
    <Snackbar />
    <v-navigation-drawer
      v-if="getLoggedIn"
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group>
          <v-list-item
            to="/"
          >
            <v-list-item-title>
              Reports
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            to="/events/"
          >
            <v-list-item-title>
              Events
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            to="/blocks/"
          >
            <v-list-item-title>
              Blocks
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            to="/bwhog/"
          >
            <v-list-item-title>
              Bandwidth Hogs
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            to="/tasks/"
          >
            <v-list-item-title>
              Tasks
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            to="/forums/"
          >
            <v-list-item-title>
              Forums
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            to="/threads/"
          >
            <v-list-item-title>
              Threads
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            to="/manualReport/"
          >
            <v-list-item-title>
              Manual Report
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar>
      <v-app-bar-nav-icon
        v-if="getLoggedIn"
        @click.stop="drawer = !drawer"
      />

      <img
        style="cursor: pointer;"
        class="mr-3 pt-1"
        src="@/assets/img/logo.svg"
        height="75"
        @click="headerClicked"
      >
      <v-spacer />
      <v-menu
        v-if="getLoggedIn"
        :close-on-click="
          true"
        :close-on-content-click="true"
      >
        <template
          v-slot:activator="{ on }"
        >
          <v-btn
            :color="unreadCount === 0 ? 'primary' : 'success'"
            dark
            v-on="on"
          >
            {{ getUsername }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            class="error"
            @click="doLogout()"
          >
            <v-list-item-title>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-banner
      v-if="getHeaderBannerMessage.show"
      single-line
      :class="getHeaderBannerMessage.classString"
    >
      {{ getHeaderBannerMessage.text }}
    </v-banner>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Snackbar from './technical/Snackbar.vue';

export default {
  name: 'Header',
  components: {
    Snackbar,
  },
  data() {
    return {
      drawer: false,
      overlay: false,
      overlayRegister: false,
      showSnackbar: false,
      unreadCount: 0,
    };
  },
  computed: mapGetters(['getJWT', 'getLoggedIn', 'getUsername', 'getAdmin', 'loginOverlayShown', 'loginOverlayShowsRegister', 'getHeaderBannerMessage', 'getSnackbar', 'getAcceptedTerms']),
  mounted() {
  },
  methods: {
    doLogout() {
      this.$store.commit('clearState');
      window.localStorage.clear();
      this.$router.push('/login');
    },
    headerClicked() {
      this.$router.push('/').catch(err => {
      // Ignore the vuex err regarding  navigating to the page they are already on.
        if (err.name !== 'NavigationDuplicated') {
        // But ignore any other errors to the console
        } else {
          this.$router.go();
        }
      });
    },
  },

};
</script>
