import VuexPersistence from 'vuex-persist';

import Vue from 'vue';
import Vuex from 'vuex';

/* eslint no-shadow: ["error", { "allow": ["state"] }] */

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

Vue.use(Vuex);


const getDefaultState = () => ({
  jwt: '',
  user: {
    admin: 'false',
    name: '',
    email: '',
    id: '',
    loggedIn: false,
  },
  pendingMessage: '',
  loginOverlayShown: false,
  loginOverlayShowsRegister: false,
  headerBannerMessage: {
    show: false,
    text: '',
    classString: '',
  },
  snackbar: {
    text: '',
    color: '',
  },
  attempts: {},
  acceptedTerms: false,
});

const state = getDefaultState();

const getters = {
  getJWT: currentState => currentState.jwt,
  getLoggedIn: currentState => currentState.user.loggedIn,
  getPendingMessage: currentState => currentState.pendingMessage,
  getAdmin: currentState => currentState.user.admin,
  getUsername: currentState => currentState.user.name,
  getEmail: currentState => currentState.user.email,
  getUserID: currentState => currentState.user.id,
  loginOverlayShown: currentState => currentState.loginOverlayShown,
  loginOverlayShowsRegister: currentState => currentState.loginOverlayShowsRegister,
  getHeaderBannerMessage: currentState => currentState.headerBannerMessage,
  getSnackbar: currentState => currentState.snackbar,
  getAttempts: currentState => taskID => {
    if (currentState.attempts[taskID]) {
      return currentState.attempts[taskID];
    }
    return null;
  },
  getAcceptedTerms: currentState => currentState.acceptedTerms,

};
const mutations = {
  setJWT(state, newToken) {
    state.jwt = newToken;
    state.user.loggedIn = true;
  },
  setShowLogin(state, showLogin) {
    state.loginOverlayShown = showLogin;
  },
  setPendingMessage(state, newMessage) {
    state.pendingMessage = newMessage;
  },
  setUser(state, userObject) {
    state.user.id = userObject.id;
    state.user.name = userObject.name;
    state.user.admin = userObject.admin;
    state.user.email = userObject.email;
  },
  setShowRegister(state, showRegister) {
    state.loginOverlayShowsRegister = showRegister;
  },
  setHeaderBannerMessage(state, bannerMessage) {
    state.headerBannerMessage = bannerMessage;
  },
  setSnackbar(state, snackbarMessage) {
    state.snackbar = snackbarMessage;
  },
  updateAttempts(state, taskID) {
    if (state.attempts[taskID]) {
      const newCount = state.attempts[taskID] + 1;
      state.attempts[taskID] = newCount;
    } else {
      state.attempts[taskID] = 1;
    }
  },
  resetAttempts(state, taskID) {
    if (state.attempts[taskID]) {
      state.attempts[taskID] = 0;
    }
  },
  acceptTerms(state) {
    state.acceptedTerms = true;
  },
  rejectTerms(state) {
    state.acceptedTerms = false;
  },
  clearState(state) {
    Object.assign(state, getDefaultState());
  },
};
const actions = {};

const plugins = [vuexLocal.plugin];


export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  plugins,
});
