<template>
  <v-btn v-if="loadedUser && loadedUser.name && loadedUser.name.length > 0" class="pl-2" x-large text :to="linkToProfile ? `/user/${userID}` : ``" @click="buttonClicked">
    <v-avatar class="mr-3 ml-0">
      <img :src="avatar" />
    </v-avatar>
    {{ loadedUser.name }}
  </v-btn>
</template>

<script>
import tools from '../../tools';
import apiClient from '../../apiClient';

export default {
  name: 'UserStats',
  components: {},
  props: {
    userID: {
      type: String,
      required: true,
      default: '',
    },
    linkToProfile: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      loadedUser: {},
    };
  },
  computed: {
    avatar() {
      return tools.getImageStringForUser(this.loadedUser);
    },
  },
  created() {
    if (this.userID && this.userID.length > 0) {
      const requestObject = {
        userID: this.userID,
      };
      apiClient
        .post('/adminUserDetail', requestObject)
        .then((response) => {
          this.loadedUser = response.data;
        })
        .catch(() => {
          // err
        });
    }
  },
  methods: {
    buttonClicked() {
      if (!this.linkToProfile) {
        this.$emit('userButtonClicked');
      }
    },
  },
};
</script>

<style scoped>
* {
  text-transform: none !important;
}
</style>
