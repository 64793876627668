import Vue from 'vue';
import Router from 'vue-router';

import Home from './views/Home.vue';
import Blocks from './views/Blocks.vue';
import Login from './views/Login.vue';
import Tasks from './views/Tasks.vue';
import Task from './views/Task.vue';
import Solution from './views/Solution.vue';
import DoTask from './views/DoTask.vue';
import Completions from './views/Completions.vue';
import User from './views/User.vue';
import BandwidthHog from './views/BandwidthHog.vue';
import ManualReport from './views/ManualReport.vue';

import NewForum from './views/NewForum.vue';
import ipa from './views/ipa.vue';
import Forums from './views/Forums.vue';
import Threads from './views/Threads.vue';
import Reports from './views/Reports.vue';
import Report from './views/Report.vue';

import Conversation from './views/Conversation.vue';
import Conversations from './views/Conversations.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/events',
      name: 'home',
      component: Home,
    },
    {
      path: '/bwhog',
      name: 'BandwidthHog',
      component: BandwidthHog,
    },
    {
      path: '/blocks',
      name: 'blocks',
      component: Blocks,
    },
    {
      path: '/forums/new',
      name: 'newForum',
      component: NewForum,
    },
    {
      path: '/forum/:forumID',
      name: 'editForum',
      component: NewForum,
    },
    {
      path: '/ipa/:ipa',
      name: 'ipa',
      component: ipa,
    },
    {
      path: '/',
      name: 'reports',
      component: Reports,
    },
    {
      path: '/reports/:reportID',
      name: 'report',
      component: Report,
    },
    {
      path: '/forums',
      name: 'forums',
      component: Forums,
    },
    {
      path: '/manualReport',
      name: 'manualReport',
      component: ManualReport,
    },
    {
      path: '/threads',
      name: 'threads',
      component: Threads,
    },
    {
      path: '/tasks',
      name: 'tasks',
      component: Tasks,
    },
    {
      path: '/task/:taskID',
      name: 'task',
      component: Task,
    },
    {
      path: '/do-task/:taskID',
      name: 'doTask',
      component: DoTask,
    },
    {
      path: '/solution/:solutionID',
      name: 'solution',
      component: Solution,
    },
    {
      path: '/completions/:taskID',
      name: 'completions',
      component: Completions,
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/user/:userID',
      name: 'user',
      component: User,
    },
    {
      path: '/conversations/:authorID',
      name: 'conversations',
      component: Conversations,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/conversations/:authorID/:recipientID',
      name: 'conversation',
      component: Conversation,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});
