<template>
  <div sticky-container>
    <v-overlay v-if="showIntroText" opacity="1">
      <div id="scrollOverlay" class="introOverlay">
        <v-row>
          <v-col>
            <UserButton :user="task.creator" />
            <v-divider class="mb-3 mt-1" />
            <rich-text :content-string="task.introText" />
            <v-btn color="primary" block @click="dismissIntroText"> Start the task </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-overlay>
    <v-overlay v-if="interruptionShown" opacity="1">
      <p class="display-1">
        <rich-text :content-string="currentInterruption" is-interruption=":true" />
      </p>
    </v-overlay>
    <v-overlay v-if="errorOverlay" opacity="1">
      <v-img src="@/assets/img/error.svg" />
    </v-overlay>
    <v-overlay :value="accessCodeFormShown" opacity="1">
      <v-row>
        <v-col>
          <p>{{ accessCodeHint }}</p>
        </v-col>
      </v-row>
      <v-form ref="accessCodeForm" v-model="accessCodeValid">
        <v-row>
          <v-col>
            <v-text-field v-model="accessCode" label="Access code" :type="showAccessCode ? 'text' : 'password'" :append-icon="showAccessCode ? 'mdi-eye' : 'mdi-eye-off'" required :rules="rulesAccessCode" @click:append="showAccessCode = !showAccessCode" />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-right">
            <v-btn outlined class="mr-4" @click="cancelAccessCode"> Cancel </v-btn>

            <v-btn color="primary" :disabled="!accessCodeValid" @click="submitAccessCode"> Submit </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-overlay>
    <vue-headful :title="`${task.name || 'Task'} | Write for me!`" :description="`Home page of Write for me!`" />
    <div v-if="!showIntroText">
      <v-row>
        <v-col>
          <h2 class="headline">
            {{ task.name }}
          </h2>
        </v-col>
      </v-row>
      <v-row v-if="messageTask && task.creator">
        <v-col>
          <p class="success text-center">Complete this task to send your message to {{ task.creator.name }}.</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="title secondary--text"> You will type the line: </v-col>
      </v-row>
      <div v-sticky class="grey darken-3 pa-3">
        {{ task.text }}
      </div>
      <v-row v-if="task.blindFlight">
        <v-col class="title text-right secondary--text"> until you are done. </v-col>
      </v-row>
      <v-row v-if="!task.blindFlight">
        <v-col class="title text-right secondary--text"> {{ realLinesToType }} times correctly. </v-col>
      </v-row>

      <v-row>
        <v-col class="">
          <v-card elevation="3">
            <v-card-text v-if="!typingStarted">
              <p class="text-center ma-0 primary--text display-1">Start typing on your keyboard to begin...</p>
            </v-card-text>
            <v-card-text v-if="typingStarted">
              <completed-line v-for="(line, lineIndex) in completedLines" :key="lineIndex" :line="line" :blind="task.blindType" />
              <p v-if="!taskFinished && !task.blindType" id="currentLine" class="title">{{ currentLine }}<span class="primary--text">|</span></p>
              <p v-if="!taskFinished && task.blindType" id="currentLine" class="title">Keep typing your line...</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-progress-linear :value="progressPercent" />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right mb-0 pb-0"> Lines yet to write </v-col>
            <v-col class="mb-0 pb-0">
              <span class="primary--text">{{ realLinesToType - correctLines }}</span>
            </v-col>
          </v-row>
          <v-row class="mb-0 mt-0 pb-0 pt-0">
            <v-col class="text-right mb-0 mt-0 pb-0 pt-0"> Mistakes </v-col>
            <v-col class="mb-0 mt-0 pb-0 pt-0">
              <span class="primary--text">{{ mistakes }}</span>
            </v-col>
          </v-row>
          <v-row class="mb-0 mt-0 pb-0 pt-0">
            <v-col class="text-right mb-0 mt-0 pb-0 pt-0"> Time since last keystroke </v-col>
            <v-col class="mb-0 mt-0 pb-0 pt-0">
              <span class="primary--text">{{ lastKeyTimeString }}</span>
            </v-col>
          </v-row>
          <v-row class="mb-0 mt-0 pb-0 pt-0">
            <v-col class="text-right mb-0 mt-0 pb-0 pt-0"> Total time </v-col>
            <v-col class="mb-0 mt-0 pb-0 pt-0">
              <span class="primary--text">{{ completeTimeString }}</span>
            </v-col>
          </v-row>
          <v-row class="mb-0 mt-0 pb-0 pt-0">
            <v-col class="text-right mb-0 mt-0 pb-0 pt-0"> Letters per minute </v-col>
            <v-col class="mb-0 mt-0 pb-0 pt-0">
              <span class="primary--text">{{ lettersPerMinute }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="task.creator" class="text-right">
        <v-col>
          <span class="subtitle-1">You're typing this task for </span>
          <UserButton :user-i-d="task.creator._id" />
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-right">
          <v-switch v-model="auto" @change="autoToggled" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <SolutionList list-type="taskSolutions" :list-identifier="$route.params.taskID" :list-length="5" :show-search="false" :access-code="accessCodeComputed" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import humanizeDuration from 'humanize-duration';
import { Howl } from 'howler';
import emojiRegex from 'emoji-regex';

import { mapGetters } from 'vuex';
import apiClient from '../apiClient';

import RichText from '../components/divViews/RichText.vue';
import UserButton from '../components/divViews/UserButton.vue';
import CompletedLine from '../components/divViews/CompletedLine.vue';
import SolutionList from '../components/listViews/SolutionListView.vue';
import tools from '../tools';

export default {
  name: 'Task',
  components: {
    RichText,
    UserButton,
    CompletedLine,
    SolutionList,
  },
  data() {
    return {
      auto: false,
      autoTimeout: null,
      taskCounted: false,
      typingSpeed: 250,
      attempts: 0,
      errorSound: false,
      task: {},
      typingStarted: false,
      taskFinished: false,
      currentLine: '',
      letterCounter: 0,
      completedLines: [],
      charInLine: 0,
      correctLines: 0,
      realLinesToType: 0,
      mistakes: 0,
      startTime: null,
      lastKeyTime: null,
      lastTimePunishmentTime: null,
      currentTime: null,
      lineStartTime: null,
      tickerInterval: null,
      updateInterval: 10,
      accessCodeFormShown: false,
      accessCodeHint: '',
      showAccessCode: false,
      accessCodeValid: false,
      accessCode: '',
      errorOverlay: false,
      pauseTyping: false,
      interruptionShown: false,
      currentInterruption: '',
      lettersSinceLastInterruption: 0,
      lettersForNextInterruption: 0,
      interruptionIndex: 0,
      errorHowl: null,
      waitHowl: null,
      showIntroText: false,
      introTextArray: [],
      rulesAccessCode: [
        (v) => {
          const regEx = /^[0-9a-zA-Z]+$/;
          const valResult = v.match(regEx);
          let retVal = false;
          if (valResult) {
            retVal = true;
          }
          return retVal || 'Please use alphanumeric characters only!';
        },
        (v) => !(v.length < 1) || "The access code can't be empty!",
      ],
    };
  },
  computed: {
    ...mapGetters(['getAttempts']),
    completeTimeString() {
      const difference = this.currentTime - this.startTime;
      return humanizeDuration(difference, { round: true });
    },
    lastKeyTimeString() {
      const difference = this.currentTime - this.lastKeyTime;
      return humanizeDuration(difference, { round: true });
    },
    lettersPerMinute() {
      const difference = this.currentTime - this.startTime;
      if (difference > 0) {
        return Math.round(this.letterCounter / (difference / 1000 / 60));
      }
      return 0;
    },
    progressPercent() {
      if (this.task && this.task.text) {
        const lettersToType = this.realLinesToType * this.task.text.length;
        if (lettersToType > 0) {
          return (this.letterCounter / lettersToType) * 100;
        }
      }
      return 0;
    },
    accessCodeComputed() {
      if (this.$route.query.accessCode && this.$route.query.accessCode.length > 0) {
        return this.$route.query.accessCode;
      }
      return '';
    },
    messageTask() {
      if (!this.$route.query.accessCode && this.$route.query.action && this.$route.query.action === 'submitMessage') {
        return true;
      }
      return false;
    },
  },
  created() {},
  beforeDestroy() {
    clearInterval(this.tickerInterval);
    window.removeEventListener('keypress', this.onkey);
  },
  mounted() {
    if (this.$route.params.taskID) {
      let requestObject = {};
      requestObject = {
        taskID: this.$route.params.taskID,
      };
      if (this.$route.query.accessCode && this.$route.query.accessCode.length > 0) {
        requestObject.accessCode = this.$route.query.accessCode;
      }
      apiClient
        .post('/taskDetail', requestObject)
        .then((response) => {
          this.task = response.data;
          this.realLinesToType = this.task.totalLines;
          // Prepare intro text:
          if (this.task.introText && this.task.introText.length > 0 && !this.messageTask) {
            this.showIntroText = true;
            this.pauseTyping = true;
          }
          if (this.task.useInterruptions) {
            this.resetInterruptionsCounter();
            this.waitHowl = new Howl({
              src: ['../../sounds/tiktok.mp3'],
            });
          }
          if (this.task.sound && this.task.sound.length > 0 && this.task.sound !== 'no sound') {
            this.errorSound = true;
            const soundUrl = `../../sounds/${this.task.sound}.mp3`;
            this.errorHowl = new Howl({
              src: [soundUrl],
            });
          }
          window.addEventListener('keypress', this.onkey);
        })
        .catch((err) => {
          const responseCode = err.response.status;
          if (responseCode === 411) {
            // Wrong code
            this.accessCodeFormShown = true;
            this.accessCodeHint = 'The access code you entered was wrong. Please try again!';
          } else if (responseCode === 401) {
            // Code needed
            this.accessCodeFormShown = true;
            this.accessCodeHint = 'This task is protected by an access code. Please enter it here.';
          } else {
            this.$store.commit('setSnackbar', {
              show: true,
              text: 'Sorry, this task could not be found.',
              color: 'error',
            });
            this.$router.push('/404');
          }
        });
    } else {
      // Check access code responses.
      this.$router.push('/404');
    }
  },
  methods: {
    onkey(event) {
      let corrected = false;
      let typedLetter;
      if (event) {
        if (this.taskFinished && this.accessCodeFormShown) {
          return;
        }
        event.stopPropagation();
        event.preventDefault();
        if (this.pauseTyping) {
          return;
        }
        if (!this.taskCounted) {
          this.taskCounted = true;
          this.$store.commit('updateAttempts', this.task._id);
        }
        const charCode = event.charCode ? event.charCode : event.which;

        // Return if whitespace and first line.
        if (this.completedLines.length > 0 && this.charInLine === 0 && charCode === 32) {
          return;
        }
        typedLetter = String.fromCharCode(charCode);
        if (typedLetter === '2' || typedLetter === '3') {
          corrected = true;
          if (typedLetter === '2') {
            this.typingSpeed += 5;
          } else {
            this.typingSpeed -= 5;
            if (this.typingSpeed < 50) {
              this.typingSpeed = 50;
            }
          }
          return;
        }
        if (typedLetter === '1') {
          corrected = true;
          this.auto = this.auto;
          if (this.auto) {
            clearTimeout(this.autoTimeout);
          }
        }
      }
      this.lettersSinceLastInterruption += 1;
      if (!this.typingStarted) {
        // Starting interval timer.
        this.tickerInterval = setInterval(() => {
          this.currentTime = Date.now();
          this.handleTimePunishment();
        }, this.updateInterval);

        this.typingStarted = true;
        this.startTime = Date.now();
        this.lineStartTime = Date.now();
      }
      this.lastKeyTime = Date.now();
      this.lastTimePunishmentTime = Date.now();
      const correctLetter = this.task.text[this.charInLine];
      if (this.auto) {
        typedLetter = correctLetter;
      }
      this.currentLine += typedLetter;
      let letterCorrect = typedLetter === correctLetter;
      if (this.auto) {
        letterCorrect = !event || corrected;
      }
      if (letterCorrect) {
        this.letterCounter += 1;
        this.charInLine += 1;
        // Save max break time and accumulated break time:
        let currentBreakTime = Date.now();
        let timeDifference = currentBreakTime - this.lastCorrectTime;

        if (timeDifference > this.maxBreak) {
          this.maxBreak = timeDifference;
        }
        if (timeDifference > 1500) {
          this.accuBreak += timeDifference;
        }
        this.lastCorrectTime = currentBreakTime;

        if (this.task.text.length === this.charInLine || this.realLinesToType - this.correctLines === 0) {
          this.completeLine(true);
        } else if (this.task && this.task.useInterruptions) {
          // Interruptions only on normal correct letters.
          if (this.lettersSinceLastInterruption >= this.lettersForNextInterruption) {
            this.spawnInterruption();
          }
        }
      } else {
        this.completeLine(false);
        this.handleMistake();
      }
      // Scroll into view
      const currentLine = document.getElementById('currentLine');
      if (currentLine) {
        currentLine.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
      if (this.auto && !event) {
        // Determin next tick
        const randFactor = Math.random() - 0.5;
        const waitTime = this.typingSpeed + this.typingSpeed * randFactor;
        this.autoTimeout = setTimeout(() => {
          this.onkey();
        }, waitTime);
      }
    },
    preloadImages() {
      const images = [];
      this.task.interruptions.forEach((interruption) => {
        const imgItems = tools.getRestrictedParagraphs(interruption).filter((item) => item.type === 'img');

        imgItems.forEach((item) => {
          if (item.url) {
            images.push(item.url);
          }
        });
      });

      images.forEach((imageUrl) => {
        const img = new Image();
        img.src = imageUrl;
      });
    },
    completeLine(correct) {
      // Add timestamp for new line
      const now = Date.now();
      const timeForLine = now - this.lineStartTime;

      const newLineObject = {
        correct,
        text: this.currentLine,
        timeForLine,
        lpt: this.charInLine / timeForLine,
      };
      this.completedLines.push(newLineObject);
      this.charInLine = 0;
      this.currentLine = '';
      this.lineStartTime = Date.now();
      if (correct) {
        this.correctLines += 1;
        if (this.correctLines === this.realLinesToType) {
          this.handleTaskFinished();
        }
      }
    },
    handleMistake() {
      if (this.errorSound) {
        this.errorHowl.play();
      }
      this.doChineseWhisper();
      this.pauseTyping = true;
      this.errorOverlay = true;
      setTimeout(() => {
        this.errorOverlay = false;
        this.pauseTyping = false;
      }, 500);
      this.mistakes += 1;
      if (this.task.punishmentLines && this.task.punishmentLines > 0) {
        this.realLinesToType += this.task.punishmentLines;
        let snackbarText = 'An additional line was added because you made a mistake!';
        if (this.task.punishmentLines > 1) {
          snackbarText = `${this.task.punishmentLines} additional lines were added because you made a mistake!`;
        }
        if (this.task.chineseWhisper) {
          snackbarText = `${snackbarText} Also, a character has been randomized.`;
        }
        this.$store.commit('setSnackbar', {
          show: true,
          text: snackbarText,
          color: 'primary',
        });
      }
    },
    handleTaskFinished() {
      clearInterval(this.tickerInterval);
      this.currentTime = Date.now();
      window.removeEventListener('keypress', this.onkey);
      this.taskFinished = true;
      this.attempts = this.getAttemptsFromStore();
      this.$store.commit('resetAttempts', this.task._id);

      // Create solution object:
      const payload = {
        totalTime: this.currentTime - this.startTime,
        totalLines: this.correctLines,
        // eslint-disable-next-line max-len
        lettersPerMinute: Math.round(this.letterCounter / ((this.currentTime - this.startTime) / 1000 / 60)),
        lines: this.completedLines,
        mistakes: this.mistakes,
        taskRef: this.$route.params.taskID,
        attempts: this.attempts,
      };

      if (this.task.chineseWhisper) {
        payload.finishedLine = this.task.text;
      }

      apiClient
        .post('/commitTask', payload)
        .then((response) => {
          if (response.data.id) {
            const solutionID = response.data.id;
            const { accessCode } = response.data;

            // Redirect to completion page.
            if (this.messageTask) {
              this.$router.push({ name: 'solution', params: { solutionID }, query: { action: 'submitMessage' } });
            } else {
              this.$router.push({ name: 'solution', params: { solutionID }, query: { accessCode } });
            }
          } else {
            this.$store.commit('setSnackbar', {
              show: true,
              text: 'Sorry, something went wrong saving your progress.',
              color: 'error',
            });
            this.$router.push('//');
          }
        })
        .catch(() => {
          this.$store.commit('setSnackbar', {
            show: true,
            text: 'Sorry, something went wrong saving your progress.',
            color: 'error',
          });
          this.$router.push('//');
        });
    },
    resetInterruptionsCounter() {
      this.lettersForNextInterruption = this.task.interruptionFrequency * 0.75 + Math.floor(Math.random() * (this.task.interruptionFrequency * 0.5));
      this.lettersSinceLastInterruption = 0;
    },
    spawnInterruption() {
      this.waitHowl.play();
      // Which interruption to show
      if (this.task.randomInterruptions) {
        this.currentInterruption = this.task.interruptions[Math.floor(Math.random() * this.task.interruptions.length)];
      } else {
        if (this.interruptionIndex >= this.task.interruptions.length) {
          this.interruptionIndex = 0;
        }
        this.currentInterruption = this.task.interruptions[this.interruptionIndex];
        this.interruptionIndex += 1;
      }
      // Interruption time
      // Filter out img types and join all text types into a single string.
      const textContent = tools
        .getRestrictedParagraphs(this.currentInterruption)
        .filter((item) => item.type === 'text')
        .map((item) => item.text)
        .join('');

      // Determine interruption time based on the length of the text content.
      let interruptionTime = textContent.length * 75;

      if (this.task.punishmentTime && this.task.punishmentTime > 0 && interruptionTime >= this.task.punishmentTime * 1000) {
        interruptionTime = this.task.punishmentTime * 900;
      }

      if (interruptionTime < 1000) {
        interruptionTime = 1000;
      }
      if (interruptionTime > 14000) {
        interruptionTime = 14000;
      }
      this.pauseTyping = true;
      this.interruptionShown = true;
      this.resetInterruptionsCounter();

      setTimeout(() => {
        this.interruptionShown = false;
        this.pauseTyping = false;
      }, interruptionTime);
    },
    submitAccessCode() {
      this.$router.push({ name: 'doTask', params: { taskID: this.$route.params.taskID }, query: { accessCode: this.accessCode } });
    },
    cancelAccessCode() {
      this.$router.push('//');
    },
    dismissIntroText() {
      this.showIntroText = false;
      this.pauseTyping = false;
    },
    getAttemptsFromStore() {
      return this.getAttempts(this.task._id);
    },
    handleTimePunishment() {
      if (this.task && this.task.punishmentTime && this.task && this.task.punishmentTime > 0 && this.task.punishmentTimeLines && this.task && this.task.punishmentTimeLines > 0) {
        const difference = this.currentTime % this.lastTimePunishmentTime;
        if (difference >= this.task.punishmentTime * 1000) {
          this.lastTimePunishmentTime = Date.now();
          this.realLinesToType += this.task.punishmentTimeLines;

          let snackbarText = 'An additional line was added because you took a break!';
          if (this.task.punishmentTimeLines > 1) {
            snackbarText = `${this.task.punishmentTimeLines} additional lines were added because you took a break!`;
          }
          this.$store.commit('setSnackbar', {
            show: true,
            text: snackbarText,
            color: 'primary',
          });
        }
      }
    },
    doChineseWhisper() {
      if (!this.task.chineseWhisper) {
        return;
      }
      // Buchstabe austauschen
      const textArray = Array.from(this.task.text); // To handle emojis
      const charIndexToChange = Math.floor(Math.random() * textArray.length);
      let newChar;

      const character = textArray[charIndexToChange];

      // Check if the character is a letter
      if (/[a-z]/.test(character)) {
        newChar = this.getRandomChar('lowerLetter');
      } else if (/[A-Z]/.test(character)) {
        newChar = this.getRandomChar('upperLetter');
      } else if (emojiRegex().test(character)) {
        newChar = this.getRandomChar(Math.random() < 0.5 ? 'lowerLetter' : 'upperLetter');
      } else {
        newChar = this.getRandomChar('symbol');
      }

      textArray[charIndexToChange] = newChar;
      this.task.text = textArray.join('');
    },

    getRandomChar(type) {
      let pool = '';
      if (type === 'lowerLetter') {
        pool = 'abcdefghijklmnopqrstuvwxyz';
      } else if (type === 'upperLetter') {
        pool = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      } else if (type === 'symbol') {
        pool = ',.?/\\(^)![]{}*&^%$#\'"';
      }
      const arr = pool.split('');
      return arr[Math.floor(Math.random() * arr.length)];
    },

    replaceChar(origString, replaceChar, index) {
      const firstPart = origString.substr(0, index);
      const lastPart = origString.substr(index + 1);

      const newString = firstPart + replaceChar + lastPart;
      return newString;
    },
    autoToggled() {
      if (this.auto) {
        this.onkey();
      }
    },
  },
};
</script>

<style scoped>
.introOverlay {
  min-width: 400px;
  max-width: 800px;
  padding-right: 30px;
  padding-left: 30px;
}

div#scrollOverlay {
  overflow: auto;
  max-height: 90vh;
  max-width: 100vw;
}
</style>
